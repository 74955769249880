@import "@aws-amplify/ui-react/styles.css";

@font-face {
  font-family: avenir;
  src: url(assetts/fonts/Avenir.ttc);
}

.avenir {
  font-family: avenir;
}

.app {
  text-align: center;
}

[data-amplify-theme] {
  --amplify-borders-primary: var(--amplify-border-widths-small) solid var(--amplify-colors-border-primary);
  --app-header-height: 4rem;
  --app-nav-bar-width: 15rem;
}

.amplify-button {
  line-height: normal;
}

.app-header {
  align-items: center;
  gap: 2em;
  padding: var(--amplify-space-small) var(--amplify-space-xl);
  display: flex;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--amplify-colors-background-primary);
  z-index: 100;
  border-bottom: var(--amplify-borders-primary);
  flex-direction: row;
  height: var(--app-header-height);
}

.app-nav {
  display: flex;
  flex-grow: 1;
}

.main {
  display: flex;
  flex-direction: row;
  min-width: 0;
  flex: 1 0 auto;
}

.side-bar {
  border-right: var(--amplify-borders-primary);
  height: calc(100vh - var(--app-header-height));
  width: var(--app-nav-bar-width);
}

.menu-heading {
  padding: 1em !important;
  font-weight: 600 !important;
  color: var(--amplify-colors-brand-primary-100);
  text-align: left;
}

.menu-link {
  padding: 1em !important;
  font-weight: 400 !important;
  margin: 0 1rem 0 1rem;
  text-align: left;
  cursor: pointer;
  color: var(--amplify-colors-font-secondary) !important;
}

.menu-link.active {
  background-color: var(--amplify-colors-brand-primary-10);
  color: var(--amplify-colors-font-hover) !important;
}

.main-content {
  width: calc(100vw - var(--app-nav-bar-width));
  padding: 1.5em;
  text-align: left;
  background-color: var(--amplify-colors-neutral-10, #e1e5e9);
}

.page-card {
  width: 100%;
}

.dialog-pane {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
}


.dialog-content {
  min-width: 50vw;
}

.ln-gradient {
  background: rgb(100, 95, 170);
  background: linear-gradient(39deg, rgba(100, 95, 170, 1) 30%, rgba(0, 114, 188, 1) 60%, rgba(120, 182, 228, 1) 100%);
}

.nav-logo-container {
  width: fit-content;
  height: fit-content;
}

.nav-btn-container {
  margin-left: auto;
  display: flex;
  gap: 10px;
}


.hero-image {
  background: linear-gradient(193deg, rgba(100, 95, 170, 0.5) 26%, rgba(0, 114, 188, 0.5) 66%, rgba(120, 182, 228, 0.4990371148459384) 100%), url("./assetts/images/hero_img_2.jpg");
  height: 755px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 5em;
}

.hero-text .avenir {
  font-family: avenir;
  font-weight: var(--amplify-font-weights-bold);
  text-shadow: 2px 2px 7px #828282;
}

.detail-container {
  height: fit-content;
  min-height: 500px;
  display: flex;
  justify-content: center;
  margin: 50px 0 50px 0;
}

.detail-header {
  height: fit-content;
  width: 45%;
  margin: auto;
}

.detail-section {
  width: 90%;
  margin: auto;
  display: flex;
  flex: wrap;
  gap: 25px;
}

.detail-section-desc {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.contact{
  text-align: center;
  width: fit-content;
  margin: auto;
  margin-top: 50px;
}

.contact a{
  color: #364B9B;
  font-size: large;
}



@media only screen and (max-width: 1280px) {
  .hero-image {
    height: 600px;
  }

  .hero-text {
    top: 52%;
    font-size: 4em;
  }
}

@media only screen and (max-width: 414px) {
  .hero-image {
    height: 600px;
  }

  .hero-text {
    top: 35%;
    font-size: 2.1em;
  }

  .nav-btn-container {
    display: none;
  }

  .nav-logo-container {
    margin: auto;
  }

  .detail-header {
    width: 95%;
  }

  .detail-section {
    flex-direction: column;
  }

  .victory {
    flex-direction: column-reverse;
    padding: 40px 0 10px 0;
  }

  .detail-section-desc {
    width: 100%;
  }
}